import React, { useEffect, useState } from "react"
import {
  GoogleMap,
  Marker,
  useJsApiLoader,
  InfoBox,
} from "@react-google-maps/api"

import { useWebsiteConfiguration } from "~queries/hooks"

/* Import Global Component(s) */
import Card from "~components/card/card"

/* Import Local Style(s) */
import "./map.scss"


var mapStyles = [
  {
    featureType: "water",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#ededed",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "labels.text",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "transit",
    stylers: [
      {
        color: "#808080",
      },
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry.stroke",
    stylers: [
      {
        visibility: "off",
      },
      {
        color: "#b3b3b3",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#ffffff",
      },
    ],
  },
  {
    featureType: "road.local",
    elementType: "geometry.fill",
    stylers: [
      {
        visibility: "on",
      },
      {
        color: "#ffffff",
      },
      {
        weight: 1.8,
      },
    ],
  },
  {
    featureType: "road.local",
    elementType: "geometry.stroke",
    stylers: [
      {
        visibility: "off",
      },
      {
        color: "#d7d7d7",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "geometry.fill",
    stylers: [
      {
        visibility: "on",
      },
      {
        color: "#ebebeb",
      },
    ],
  },
  {
    featureType: "administrative",
    elementType: "geometry",
    stylers: [
      {
        color: "#a7a7a7",
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#ffffff",
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#ffffff",
      },
    ],
  },
  {
    featureType: "landscape",
    elementType: "geometry.fill",
    stylers: [
      {
        visibility: "on",
      },
      {
        color: "#f7f7f7",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#696969",
      },
    ],
  },
  {
    featureType: "administrative",
    elementType: "labels.text.fill",
    stylers: [
      {
        visibility: "on",
      },
      {
        color: "#858585",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "labels",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "geometry.stroke",
    stylers: [
      {
        color: "#ffffff",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {},
  {
    featureType: "poi",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#dadada",
      },
    ],
  },
]

const Map = ({ lat = 25.783928, lng = -80.1299544, labels = []}) => {

  const center = { lat, lng }

  const { coverAnimation } = useWebsiteConfiguration()

  const [zoom, setZoom] = useState(16)
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyAox-A8QBAOW2FV0N7BKogUljujGBnEa4A",
  })

  useEffect(() => {
    if (typeof window !== "undefined") {
      const windowWith = window.innerWidth
      if (windowWith < 768) {
        setZoom(14)
      }
    }
  }, [])

  const handleLoad = map => {
    if (typeof window !== "undefined" && isLoaded) {
      const windowWith = window.innerWidth
      if (windowWith < 768) {
        map.panBy(100, 100)
      } else {
        map.panBy(-50, 50)
      }
    }
  }

  return (
    <Card className="map" backgroundColor="var(--color-lightest-gray)">
      {isLoaded ? (
        <GoogleMap
          mapContainerStyle={{
            position: "absolute",
            top: 0,
            left: 0,
            height: "100%",
            width: "100%",
          }}
          options={{
            styles: mapStyles,
            disableDefaultUI: true,
          }}
          center={center}
          zoom={zoom}
          clickableIcons={false}
          onLoad={handleLoad}
        >
          {/* Child components, such as markers, info windows, etc. */}
          <React.Fragment>
            <Marker position={center} clickable={false} />
            <InfoBox
              position={center}
              options={{
                pixelOffset: new window.google.maps.Size(30, -60),
                buttons: { close: { visible: false } },
                closeBoxURL: "",
              }}
            >
              <div className="map-marker">
                <p className="title">Untitled Art</p>
                {labels && labels.map((l, i) => (
                  <p key={l}>{l}</p>  
                ))}
              </div>
            </InfoBox>
          </React.Fragment>
        </GoogleMap>
      ) : (
        <></>
      )}
    </Card>
  )
}

export default Map
