import React, { useContext } from "react"

import classnames from "classnames"
import LocationContext from "~context/currentLocation"

import "./location.scss"

const LocationNav = ({}) => {

	const [currentLocation, setCurrentLocation] = useContext(LocationContext)


	const setMiami = () => {
		setCurrentLocation('miamiBeach')
	}

	const setHouston = () => {
		setCurrentLocation('houston')
	}

	return (<nav className="location_header font-size--small mt--2 mb--2">
		
		<button className={classnames({dark_gray: currentLocation != 'miamiBeach'})} onClick={setMiami}>Miami Beach</button>
		<button className={classnames({dark_gray: currentLocation != 'houston'})} onClick={setHouston}>Houston</button>

	</nav>)

}


export default LocationNav